
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    // VueTelInput,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      mobileno: "",
      first_name: "",
      last_name: "",
      property_address: "",
      zip: "",
      state: "",
      city: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<string>("");
    const page = ref<string>("");
    const Urlid = ref<any>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    const backurl = ref<any>("");
    // const value = ref('');
    const registration = Yup.object().shape({
      mobileno: Yup.number()
        .typeError("Please Speacify a number")
        .required()
        .label("Number"),
      first_name: Yup.string().required().label("First Name"),
      last_name: Yup.string().required().label("Last Name"),
      property_address: Yup.mixed().required().label("Property Address"),
      zip: Yup.number()
        .required()
        .typeError("Please specify the number")
        .label("Zip Code"),
      state: Yup.string().required().label("State"),
      city: Yup.string().required().label("City"),
    });

    onMounted(() => {
      const data = route.params.id;
      Urlid.value = data;
      const urlback = router.options.history.state.back;
      backurl.value = urlback;
      //  console.log('params',urlback);
      // if (data) {
      // //   // getUser(id);
      // //   // page.value = "Edit";
      //   setCurrentPageBreadcrumbs("Edit Contact", []);
      // } else {
      //   // page.value = "Add";
      setCurrentPageBreadcrumbs("Add Contact", []);
      // }
      // setCurrentPageBreadcrumbs("Add User", ["User"]);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      // const id = route.params.id;
      // if (id) {
      //   getUser(id);

      //   page.value = "Edit";
      // } else {
      //   page.value = "Add";
      // }
    });
    const getUser = (id) => {
      // var request = {
      //   url: `plan/${id}`,
      // };
      // store.dispatch(Actions.GET, request).then((data) => {
      //   if (data) {
      //     // console.log(data)
      //     formData.value = {
      //       name: data.data.name,
      //       type: data.data.type,
      //       user_count: data.data.user_count,
      //       price: data.data.price,
      //       description: data.data.description,
      //     };
      //   }
      // });
    };

    const testFunction = (event, phoneObject) => {
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);
        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          phone_number.value = phoneObject.number;
        } else {
          phone_number.value = "";
        }
      }
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // console.log('values',values);
      // console.log('resetForm',resetForm);
      // var request = {
      //   url: 'profile/changePassword',
      //   data: {
      //     old_password: values.current_password,
      //     new_password: values.password,
      //     user_id: profile.value._id
      //   }
      // }
      // Dummy delay
      // setTimeout(() => {
      // // Send login request

      if (values.first_name.trim() == "") {
        Swal.fire({
          text: "Please enter valid First Name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.last_name.trim() == "") {
        Swal.fire({
          text: "Please enter valid Last Name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.property_address.trim() == "") {
        Swal.fire({
          text: "Please enter valid Property Address!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.zip.length != 6) {
        Swal.fire({
          text: "Please enter valid Zip Code!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.state.trim() == "") {
        Swal.fire({
          text: "Please enter valid State!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.city.trim() == "") {
        Swal.fire({
          text: "Please enter valid City!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.mobileno.length != 10) {
        Swal.fire({
          text: "Please enter valid Mobile No!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var request = {
          url: "contacts",
          data: {
            group_id: route.params.id,
            mobileno: values.mobileno,
            first_name: values.first_name,
            last_name: values.last_name,
            property_address: values.property_address,
            zip: values.zip,
            city: values.city,
            state: values.state,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Contact added successfully!", "success");
              router.push({
                path: "/user/group/view-contact/" + route.params.id,
              });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      // store
      //   .dispatch(Actions.POST, request)
      //   .then((data) => {
      //     if(data){
      //       resetForm();
      //       Swal.fire({
      //         text: "You have successfully Changed your Password!",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-primary",
      //         },
      //       }).then(function () {
      //         router.push({ name: "dashboard" });

      //       });
      //     }
      //   })
      //   .catch(() => {
      //     const [error] = Object.keys(store.getters.getErrors);
      //     Swal.fire({
      //       text: store.getters.getErrors[error],
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Try again!",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //   });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      formData,
      inputOptions,
      page,
      getUser,
      Urlid,
      backurl,
    };
  },
});
